// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-aqua-js": () => import("./../../../src/pages/portfolio/aqua.js" /* webpackChunkName: "component---src-pages-portfolio-aqua-js" */),
  "component---src-pages-portfolio-covid-js": () => import("./../../../src/pages/portfolio/covid.js" /* webpackChunkName: "component---src-pages-portfolio-covid-js" */),
  "component---src-pages-portfolio-micro-js": () => import("./../../../src/pages/portfolio/micro.js" /* webpackChunkName: "component---src-pages-portfolio-micro-js" */),
  "component---src-pages-portfolio-promyk-js": () => import("./../../../src/pages/portfolio/promyk.js" /* webpackChunkName: "component---src-pages-portfolio-promyk-js" */)
}

